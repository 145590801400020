<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

import OrganizationChart from 'primevue/organizationchart';

export default {
  locales: {
    pt: {
      "This user has no sponsored.": "Este usuário não possui nenhum indicado.",
      "The user you are trying to access does not exist or is not on your network.":
        "O usuário que está tentando acessar não existe ou não está em sua rede.",
    },
    es: {
      "This user has no sponsored.": "Este usuario no tiene nominado.",
      "The user you are trying to access does not exist or is not on your network.":
        "El usuario al que intenta acceder no existe o no está en su red.",
    },
  },
  components: {
    Layout,
    OrganizationChart,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      tree: [],
      treeConfig: { nodeWidth: 200, nodeHeight: 200, levelHeight: 200 },

      loading: true,
      empty: false,
      message: "",
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz").then((response) => {
        if (response.data.status == "success") {
          this.tree = response.data.tree;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    this.getMatriz();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0 font-size-18">{{ t("Matriz") }}</h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="overflow: auto;">
            <vue-tree
              class="d-none tree"
              :dataset="tree"
              :config="treeConfig"
              :collapse-enabled="true"
            >
              <template v-slot:node="{ node }">
                <div class="tree-node">
                  <div class="tree-avatar">
                    <img
                      v-if="node.data.avatar"
                      class="rounded-circle header-profile-user"
                      :src="node.data.avatar"
                    />
                    <img
                      v-else
                      class="rounded-circle header-profile-user"
                      :src="'https://ui-avatars.com/api/?background=230270&color=fff&name=' + account.user.name"
                      alt=""
                    />
                  </div>
                  <div
                    class="tree-user"
                    >{{ node.data.username }}</div
                  >
                </div>
              </template>
            </vue-tree>

            <OrganizationChart :value="tree" :collapsible="true" selectionMode="single">
              <template #person="slotProps">
                <div class="node-content">
                  <div class="tree-avatar">
                    <img
                      v-if="slotProps.node.data.avatar"
                      class="rounded-circle header-profile-user"
                      :src="slotProps.node.data.avatar"
                    />
                    <img
                      v-else
                      class="rounded-circle header-profile-user"
                      :src="'https://ui-avatars.com/api/?background=230270&color=fff&name=' + account.user.name"
                      alt=""
                    />
                  </div>
                  <div class="tree-username font-size-12 mb-2">{{slotProps.node.data.username}}</div>
                </div>
              </template>
            </OrganizationChart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.tree {
  width: 100%;
  height: calc(100vh - 200px);
}

.tree-node {
  background: #eee;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.node-content {
  text-align: center;
  background: #eee;
  border-radius: 10px;
  padding: 10px;
  max-width: 140px;
  line-height: 14px;
}

.node-content img {
    border-radius: 50%;
}

.p-organizationchart-line-top {
  border-top: solid 1px #ccc;
}

.p-organizationchart-line-right {
  border-left: solid 1px #ccc;
}

.p-organizationchart-line-down {
  background: #ccc;
}

.p-node-toggler-icon {
  background: #fff;
  border-radius: 50px;
  padding: 5px;
}
</style>